var upList = {
    getList: (root, query) => {
        return new Promise((resolve) => {
            console.log(query)
            let categoryId = query.categoryId
            let keyword = query.keyword
            let page = query.page
            let pagesize = query.pagesize
            console.log(categoryId)
            let item = root[0].children.find(e => e.id == categoryId)
            let res = {
                list: [],
                total: 0
            }
            console.log(item)
            if (item && item.parts) {
                let list = item.parts
                if (keyword) {
                    list = list.filter(e => e.name.indexOf(keyword) != -1)
                }
                res.total = list.length
                let start = (page - 1) * pagesize
                let end = page * pagesize
                list = list.slice(start, end)
                list.forEach(e=>{
                    e.laborItem = {
                        maxLoadQty: e.maxLoadQty,
                    }
                })
                res.list = list
            }
            resolve(res)
        })
    },
    data: [
        {
            id: 10000,
            name: '上装件',
            children: [
                {
                    id: 10100,
                    name: "9.6高栏",
                    children: [],
                    parts: [
                        //立柱：10个
                        {
                            id: 10011,
                            name: "立柱",
                            maxLoadQty: 10,
                            unit: '个',
                            parts: []
                        },
                        // 马槽钩：48个
                        {
                            id: 10012,
                            name: "马槽钩",
                            maxLoadQty: 48,
                            unit: '个',
                            parts: []
                        },
                        // 箱门锁具：4套
                        {
                            id: 10013,
                            name: "箱门锁具",
                            maxLoadQty: 4,
                            unit: '套',
                            parts: []
                        },
                        // 后门锁杆：4根
                        {
                            id: 10014,
                            name: "后门锁杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                        // 边灯：6个
                        {
                            id: 10015,
                            name: "边灯",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        // 挡泥板：4个
                        {
                            id: 10016,
                            name: "挡泥板",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        // 花栏：16个
                        {
                            id: 10017,
                            name: "花栏",
                            maxLoadQty: 16,
                            unit: '个',
                            parts: []
                        },
                        // 侧挡板：8个
                        {
                            id: 10018,
                            name: "侧挡板",
                            maxLoadQty: 8,
                            unit: '个',
                            parts: []
                        },
                        //标杆篷布杆：18个
                        {
                            id: 10019,
                            name: "标杆篷布杆",
                            maxLoadQty: 18,
                            unit: '个',
                            parts: []
                        },
                        // 爬梯：1个
                        {
                            id: 10020,
                            name: "爬梯",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10021,
                            name: "行李架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10022,
                            name: "紧绳器",
                            maxLoadQty: 16,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10023,
                            name: "U型螺栓",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10024,
                            name: "后箱门",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10025,
                            name: "工具箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10026,
                            name: "副车架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //9.6箱货：	后箱门：2个	后示廓灯：2个	后箱门锁具：4套	工具箱：2个	测防护：2个	侧箱门：2个	挡泥板：2个	边灯：8个
                //侧门锁具：4个	侧门锁杆：4根	后箱门锁杆：4根	箱门限位器：4个
                {
                    id: 10200,
                    name: "9.6箱货",
                    children: [],
                    parts: [
                        //后箱门：2个
                        {
                            id: 10031,
                            name: "后箱门",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //后示廓灯：2个
                        {
                            id: 10032,
                            name: "后示廓灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //后箱门锁具：4套
                        {
                            id: 10033,
                            name: "后箱门锁具",
                            maxLoadQty: 4,
                            unit: '套',
                            parts: []
                        },
                        //工具箱：2个
                        {
                            id: 10034,
                            name: "工具箱",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //测防护：2个
                        {
                            id: 10035,
                            name: "测防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //侧箱门：2个
                        {
                            id: 10036,
                            name: "侧箱门",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //挡泥板：2个
                        {
                            id: 10037,
                            name: "挡泥板",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //边灯：8个
                        {
                            id: 10038,
                            name: "边灯",
                            maxLoadQty: 8,
                            unit: '个',
                            parts: []
                        },
                        //侧门锁具：4个
                        {
                            id: 10039,
                            name: "侧门锁具",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        //侧门锁杆：4根
                        {
                            id: 10040,
                            name: "侧门锁杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                        //后箱门锁杆：4根
                        {
                            id: 10041,
                            name: "后箱门锁杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                        //箱门限位器：4个
                        {
                            id: 10042,
                            name: "箱门限位器",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //13米侧翻:	侧挡板：12个	后挡板：1个	轮胎：12个	后尾灯：2个	液压油缸：7个	后防撞梁：1根	后反光板：两个	挂车悬架支架及平衡轴：4个
                //侧防护：2个	前挡板：1个	行李架：1个	紧绳器：26个	挂车支撑腿：2个	车架交接轴：1根	气路电路连接管：3个
                {
                    id: 10300,
                    name: "13米侧翻",
                    children: [],
                    parts: [
                        //侧挡板：12个
                        {
                            id: 10051,
                            name: "侧挡板",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        //后挡板：1个
                        {
                            id: 10052,
                            name: "后挡板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //轮胎：12个
                        {
                            id: 10053,
                            name: "轮胎",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        //后尾灯：2个
                        {
                            id: 10054,
                            name: "后尾灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //液压油缸：7个
                        {
                            id: 10055,
                            name: "液压油缸",
                            maxLoadQty: 7,
                            unit: '个',
                            parts: []
                        },
                        //后防撞梁：1根
                        {
                            id: 10056,
                            name: "后防撞梁",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //后反光板：两个
                        {
                            id: 10057,
                            name: "后反光板",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //挂车悬架支架及平衡轴：4个
                        {
                            id: 10058,
                            name: "挂车悬架支架及平衡轴",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        //侧防护：2个
                        {
                            id: 10059,
                            name: "侧防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //前挡板：1个
                        {
                            id: 10060,
                            name: "前挡板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
//行李架：1个
                        {
                            id: 10061,
                            name: "行李架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //紧绳器：26个
                        {
                            id: 10062,
                            name: "紧绳器",
                            maxLoadQty: 26,
                            unit: '个',
                            parts: []
                        },
                        //挂车支撑腿：2个
                        {
                            id: 10063,
                            name: "挂车支撑腿",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //车架交接轴：1根
                        {
                            id: 10064,
                            name: "车架交接轴",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //气路电路连接管：3个
                        {
                            id: 10065,
                            name: "气路电路连接管",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //13米高栏:	花栏：24个	立柱：10个	挡板：12个	后防撞梁：1根	后尾灯：2个	后反光板：2个	后警示灯：1个	挂车支撑退：2根
                //后们锁具：4套	后门锁杆：4根	平衡拉杆：4根	紧绳器：28个	备胎升降器：2个	挂车悬架支架：8个	边等：12个	挡泥板：4个
                //工具箱:4个	水罐：1个	水罐进出水口：1个	侧防护：2个	减震块：50个	绳钩：24个	后箱门：2个	行李架：1个
                //与主车连接气路接头2个	ABS线接头	电源线接头	摄像头线	弹簧钢板总成：6组	弹簧制动缸2个	刹车凸轮轴6个	制动调整臂6个
                //气包1个
                {
                    id: 10400,
                    name: "13米高栏",
                    children: [],
                    parts: [
                        //花栏：24个
                        {
                            id: 10067,
                            name: "花栏",
                            maxLoadQty: 24,
                            unit: '个',
                            parts: []
                        },
                        //立柱：10个
                        {
                            id: 10068,
                            name: "立柱",
                            maxLoadQty: 10,
                            unit: '个',
                            parts: []
                        },
                        //挡板：12个
                        {
                            id: 10069,
                            name: "挡板",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        //后防撞梁：1根
                        {
                            id: 10070,
                            name: "后防撞梁",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //后尾灯：2个
                        {
                            id: 10071,
                            name: "后尾灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //后反光板：2个
                        {
                            id: 10072,
                            name: "后反光板",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //后警示灯：1个
                        {
                            id: 10073,
                            name: "后警示灯",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //挂车支撑退：2根
                        {
                            id: 10074,
                            name: "挂车支撑退",
                            maxLoadQty: 2,
                            unit: '根',
                            parts: []
                        },
                        //后们锁具：4套
                        {
                            id: 10075,
                            name: "后们锁具",
                            maxLoadQty: 4,
                            unit: '套',
                            parts: []
                        },
                        //后门锁杆：4根
                        {
                            id: 10076,
                            name: "后门锁杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                        //平衡拉杆：4根
                        {
                            id: 10077,
                            name: "平衡拉杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                        //紧绳器：28个
                        {
                            id: 10078,
                            name: "紧绳器",
                            maxLoadQty: 28,
                            unit: '个',
                            parts: []
                        },
                        //备胎升降器：2个
                        {
                            id: 10079,
                            name: "备胎升降器",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //挂车悬架支架：8个
                        {
                            id: 10080,
                            name: "挂车悬架支架",
                            maxLoadQty: 8,
                            unit: '个',
                            parts: []
                        },
                        //边等：12个
                        {
                            id: 10081,
                            name: "边等",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        //挡泥板：4个
                        {
                            id: 10082,
                            name: "挡泥板",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        //工具箱:4个
                        {
                            id: 10083,
                            name: "工具箱",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        // 水罐：1个
                        {
                            id: 10084,
                            name: "水罐",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        // 水罐进出水口：1个
                        {
                            id: 10085,
                            name: "水罐进出水口",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        // 侧防护：2个
                        {
                            id: 10086,
                            name: "侧防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        // 减震块：50个
                        {
                            id: 10087,
                            name: "减震块",
                            maxLoadQty: 50,
                            unit: '个',
                            parts: []
                        },
                        // 绳钩：24个	后箱门：2个	行李架：1个
                        {
                            id: 10088,
                            name: "绳钩",
                            maxLoadQty: 24,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10089,
                            name: "后箱门",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10090,
                            name: "行李架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //与主车连接气路接头2个	ABS线接头	电源线接头	摄像头线	弹簧钢板总成：6组	弹簧制动缸2个	刹车凸轮轴6个	制动调整臂6个
                        {
                            id: 10091,
                            name: "与主车连接气路接头",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10092,
                            name: "ABS线接头",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10093,
                            name: "电源线接头",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10094,
                            name: "摄像头线",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10095,
                            name: "弹簧钢板总成",
                            maxLoadQty: 6,
                            unit: '组',
                            parts: []
                        },
                        {
                            id: 10096,
                            name: "弹簧制动缸",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10097,
                            name: "刹车凸轮轴",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10098,
                            name: "制动调整臂",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        //气包1个
                        {
                            id: 10099,
                            name: "气包",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //17米冷藏箱货：
                {
                    id: 10500,
                    name: "17米冷藏箱货",
                    children: [],
                    parts: [
                        // 气路管：2根	ABS连接线：1根	七星电缆线：1根	制冷机电线：1根	制冷机总成：1个	集装箱挂钩：4个	集装箱挂钩销：4个	观察孔：4个
                        {
                            id: 10501,
                            name: "气路管",
                            maxLoadQty: 2,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10502,
                            name: "ABS连接线",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10503,
                            name: "七星电缆线",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10504,
                            name: "制冷机电线",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10505,
                            name: "制冷机总成",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10506,
                            name: "集装箱挂钩",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10507,
                            name: "集装箱挂钩销",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10508,
                            name: "观察孔",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        //冷藏车厢：1个	整车气管：1根	挂车支撑腿：2个	边等：6个	支撑腿拉杆：2根	车厢水罐：1个	水罐放水开关：1个	车厢空滤总成：1个
                        {
                            id: 10509,
                            name: "冷藏车厢",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10510,
                            name: "整车气管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10511,
                            name: "挂车支撑腿",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10512,
                            name: "边等",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10513,
                            name: "支撑腿拉杆",
                            maxLoadQty: 2,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10514,
                            name: "车厢水罐",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10515,
                            name: "水罐放水开关",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10516,
                            name: "车厢空滤总成",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //空滤进气管：1根	发电机：1个	发电发动机：1个	发动机开关：1个	电源输出线：1根	挡泥板：4个	挂车边灯：6个	备胎：1个
                        {
                            id: 10517,
                            name: "空滤进气管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10518,
                            name: "发电机",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10519,
                            name: "发电发动机",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10520,
                            name: "发动机开关",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10521,
                            name: "电源输出线",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10522,
                            name: "挡泥板",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10523,
                            name: "挂车边灯",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10524,
                            name: "备胎",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //备胎支架：1个	后三桥：3个	轮胎：12个	工具箱：2个	发电机油箱：1个	防撞梁：1根	尾灯：2个	尾灯支架：2个
                        {
                            id: 10525,
                            name: "备胎支架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10526,
                            name: "后三桥",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10527,
                            name: "轮胎",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10528,
                            name: "工具箱",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10529,
                            name: "发电机油箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10530,
                            name: "防撞梁",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10531,
                            name: "尾灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10532,
                            name: "尾灯支架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //后箱门：2个	后箱门锁具：4套	后箱门锁杆：4根
                        {
                            id: 10533,
                            name: "后箱门",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10534,
                            name: "后箱门锁具",
                            maxLoadQty: 4,
                            unit: '套',
                            parts: []
                        },
                        {
                            id: 10535,
                            name: "后箱门锁杆",
                            maxLoadQty: 4,
                            unit: '根',
                            parts: []
                        },
                    ]
                },
                //半挂板车：
                {
                    id: 10600,
                    name: "半挂板车",
                    children: [],
                    parts: [
                        // 翻板：2个	限位弹簧：2个	限位链：2个	翻板轴：2根	牵引底座：1个	ABS气孔：2个	七星线接口：1个	油泵：1个
                        {
                            id: 10601,
                            name: "翻板",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10602,
                            name: "限位弹簧",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10603,
                            name: "限位链",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10604,
                            name: "翻板轴",
                            maxLoadQty: 2,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10605,
                            name: "牵引底座",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10606,
                            name: "ABS气孔",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10607,
                            name: "七星线接口",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10608,
                            name: "油泵",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //油泵开关：1个
                        {
                            id: 10609,
                            name: "油泵开关",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //环卫
                {
                    id: 10700,
                    name: "环卫",
                    children: [],
                    parts: [
                        //副发动机总成：1个	前高压喷头：17个	侧工作灯：2个	垃圾水箱：1个	侧防护：2个	水过滤器：1个	高压冲洗系统：2个	工具箱：1个
                        {
                            id: 10701,
                            name: "副发动机总成",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10702,
                            name: "前高压喷头",
                            maxLoadQty: 17,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10703,
                            name: "侧工作灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10704,
                            name: "垃圾水箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10705,
                            name: "侧防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10706,
                            name: "水过滤器",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10707,
                            name: "高压冲洗系统",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10708,
                            name: "工具箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //边灯：2个	扫盘：4个	污水处理管：1根	超宽喷嘴：3个	左右指示灯：各1个	液压油缸：1个	垃圾水箱：1个	后防撞梁：1根
                        {
                            id: 10709,
                            name: "边灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10710,
                            name: "扫盘",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10711,
                            name: "污水处理管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10712,
                            name: "超宽喷嘴",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10713,
                            name: "左右指示灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10714,
                            name: "液压油缸",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10715,
                            name: "垃圾水箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10716,
                            name: "后防撞梁",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //后尾灯：2个	后工作台：1个	喷枪：1个	后摄像头：1个	卷绕冲洗卷盘：1个	高压软管：1根	高压喷头：2个	洗车枪开关：1个
                        {
                            id: 10717,
                            name: "后尾灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10718,
                            name: "后工作台",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10719,
                            name: "喷枪",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10720,
                            name: "后摄像头",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10721,
                            name: "卷绕冲洗卷盘",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10722,
                            name: "高压软管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10723,
                            name: "高压喷头",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10724,
                            name: "洗车枪开关",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //垃圾车：
                {
                    id: 10800,
                    name: "垃圾车",
                    children: [],
                    parts: [
                        //液压油缸：4个	垃圾滑板:1	垃圾刮板：1个	推产卸料：1个	电气线束：1根	副车架：1根	后翻桶装置:1个	限位链：1根
                        {
                            id: 10801,
                            name: "液压油缸",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10802,
                            name: "垃圾滑板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10803,
                            name: "垃圾刮板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10804,
                            name: "推产卸料",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10805,
                            name: "电气线束",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10806,
                            name: "副车架",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10807,
                            name: "后翻桶装置",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10808,
                            name: "限位链",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //后门链接上绞点:2个	多路阀：1个	油泵：1个	排污水口：1个	污水管：1根	工作灯：2个	油管：4个	多路阀：3个
                        {
                            id: 10809,
                            name: "后门链接上绞点",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10810,
                            name: "多路阀",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10811,
                            name: "油泵",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10812,
                            name: "排污水口",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10813,
                            name: "污水管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 10814,
                            name: "工作灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10815,
                            name: "油管",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10816,
                            name: "多路阀",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        //车架油泵：1个	副车架：1个	污水箱：1个
                        {
                            id: 10817,
                            name: "车架油泵",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10818,
                            name: "副车架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10819,
                            name: "污水箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //轻卡高栏：
                {
                    id: 10900,
                    name: "轻卡高栏",
                    children: [],
                    parts: [
                        //爬梯：1个	行李架：1个	花栏：8个	挡板：5个	工具箱：2个	侧防护：2个	紧绳器：10个	前迎风板：1个
                        {
                            id: 10901,
                            name: "爬梯",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10902,
                            name: "行李架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10903,
                            name: "花栏",
                            maxLoadQty: 8,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10904,
                            name: "挡板",
                            maxLoadQty: 5,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10905,
                            name: "工具箱",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10906,
                            name: "侧防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10907,
                            name: "紧绳器",
                            maxLoadQty: 10,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10908,
                            name: "前迎风板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //后迎风板：1个	立柱：6个	示廓灯：2个
                        {
                            id: 10909,
                            name: "后迎风板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10910,
                            name: "立柱",
                            maxLoadQty: 6,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 10911,
                            name: "示廓灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //轻卡平板：
                {
                    id: 11000,
                    name: "轻卡平板",
                    children: [],
                    parts: [
                        //前挡板：1个	立柱：2个	侧挡板：4个	侧防护：2个	后挡板：1个	挡板合页：10个	马槽钩：8个
                        {
                            id: 11001,
                            name: "前挡板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11002,
                            name: "立柱",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11003,
                            name: "侧挡板",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11004,
                            name: "侧防护",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11005,
                            name: "后挡板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11006,
                            name: "挡板合页",
                            maxLoadQty: 10,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11007,
                            name: "马槽钩",
                            maxLoadQty: 8,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //洒水车：	水罐：1个	出水口（挂）：1个	出水口（拧）：1个	阀门：1个	水管连接胶管：1根	水泵：2个	水泵之水罐连接管：1根	挡泥板：2个
                {
                    id: 11100,
                    name: "洒水车",
                    children: [],
                    parts: [
                        {
                            id: 11101,
                            name: "水罐",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11102,
                            name: "出水口（挂）",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11103,
                            name: "出水口（拧）",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11104,
                            name: "阀门",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11105,
                            name: "水管连接胶管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 11106,
                            name: "水泵",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11107,
                            name: "水泵之水罐连接管",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        {
                            id: 11108,
                            name: "挡泥板",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //挡泥板支架：4个	水管仓：2个	洒水喷头：2个	保险杠：1个	蹬车梯：1个	加长管仓：2个	工具箱：2个	加水口：1个
                        {
                            id: 11109,
                            name: "挡泥板支架",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11110,
                            name: "水管仓",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11111,
                            name: "洒水喷头",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11112,
                            name: "保险杠",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11113,
                            name: "蹬车梯",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11114,
                            name: "加长管仓",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11115,
                            name: "工具箱",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11116,
                            name: "加水口",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //喷枪：1个	喷枪支架1个	工作护栏2个	角灯：2个
                        {
                            id: 11117,
                            name: "喷枪",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11118,
                            name: "喷枪支架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11119,
                            name: "工作护栏",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11120,
                            name: "角灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //水泥搅拌：	罐体：1个	罐体支架：4个	转盘：2个	下料挡板：1个	PTO传动轴：1个	支撑衡梁：1个	油管支架：2个	油管：1个
                {
                    id: 11200,
                    name: "水泥搅拌",
                    children: [],
                    parts: [
                        {
                            id: 11201,
                            name: "罐体",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11202,
                            name: "罐体支架",
                            maxLoadQty: 4,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11203,
                            name: "转盘",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11204,
                            name: "下料挡板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11205,
                            name: "PTO传动轴",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11206,
                            name: "支撑衡梁",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11207,
                            name: "油管支架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11208,
                            name: "油管",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //工具箱：1个	散热器：1个	散热器支架：2个	进水管：1个	出水管：2个	取力器：1个	液压油管：2个	取力器支架：1个
                        {
                            id: 11209,
                            name: "工具箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11210,
                            name: "散热器",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11211,
                            name: "散热器支架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11212,
                            name: "进水管",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11213,
                            name: "出水管",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11214,
                            name: "取力器",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11215,
                            name: "液压油管",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11216,
                            name: "取力器支架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //水罐：1个	水罐支架：1个	水箱控制盒：1个	水箱控制开关：1个	连接水管：2个	水箱控制盒支架：2个	控制下料口手柄：1个	转轴：1个
                        {
                            id: 11217,
                            name: "水罐",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11218,
                            name: "水罐支架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11219,
                            name: "水箱控制盒",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11220,
                            name: "水箱控制开关",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11221,
                            name: "连接水管",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11222,
                            name: "水箱控制盒支架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11223,
                            name: "控制下料口手柄",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11224,
                            name: "转轴",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //废料盒：1个	蹬车梯：1个	固定支架：2个	操作手柄：1个	油管控制阀：3个
                        {
                            id: 11225,
                            name: "废料盒",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11226,
                            name: "蹬车梯",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11227,
                            name: "固定支架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11228,
                            name: "操作手柄",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11229,
                            name: "油管控制阀",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                    ]
                },
                //油罐车：	防溢池：3个	人孔盖：3个	合页开启室 （下六点）3个	灯具（左右，上下）圆灯方灯：12个	紧急切断阀（开关）：1个	球阀（卸料开关）：1个	工具箱：3个	卸料管：2个
                {
                    id: 11300,
                    name: "油罐车",
                    children: [],
                    parts: [
                        {
                            id: 11301,
                            name: "防溢池",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11302,
                            name: "人孔盖",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11303,
                            name: "合页开启室 （下六点）",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11304,
                            name: "灯具（左右，上下）圆灯方灯",
                            maxLoadQty: 12,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11305,
                            name: "紧急切断阀（开关）",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11306,
                            name: "球阀（卸料开关）",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11307,
                            name: "工具箱",
                            maxLoadQty: 3,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11308,
                            name: "卸料管",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //阀门箱：1个	胶管箱（卸料管）：2个	气囊减震：2个	备胎架：2个	手摇式支腿：1个	牵引箱（危货50，普货90）：1个	危货挂车EBS：1个	普货挂车ABS：1个
                        {
                            id: 11309,
                            name: "阀门箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11310,
                            name: "胶管箱（卸料管）",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11311,
                            name: "气囊减震",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11312,
                            name: "备胎架",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11313,
                            name: "手摇式支腿",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11314,
                            name: "牵引箱（危货50，普货90）",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11315,
                            name: "危货挂车EBS",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11316,
                            name: "普货挂车ABS",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //安全护栏，安全绳：1个
                        {
                            id: 11317,
                            name: "安全护栏，安全绳",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ],
                },
                //渣土自卸：	液压油箱：1个	安全使用说明贴：1个	后箱板：1个	小灯：2个	液压油缸总成：1个	大箱翻转底座：1个	出油阀门：1个	防止磨损套：2个
                {
                    id: 11400,
                    name: "渣土自卸",
                    children: [],
                    parts: [
                        {
                            id: 11401,
                            name: "液压油箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11402,
                            name: "安全使用说明贴",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11403,
                            name: "后箱板",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11404,
                            name: "小灯",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11405,
                            name: "液压油缸总成",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11406,
                            name: "大箱翻转底座",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11407,
                            name: "出油阀门",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11408,
                            name: "防止磨损套",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        //翻转轴：1个	后翻斗门定位链：2个	定位链底座：2个	油泵吸油管（进）：2个	锁钩：2个	副架：1个	后箱拉杆：1个	单向阀：1个
                        {
                            id: 11409,
                            name: "翻转轴",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11410,
                            name: "后翻斗门定位链",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11411,
                            name: "定位链底座",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11412,
                            name: "油泵吸油管（进）",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11413,
                            name: "锁钩",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11414,
                            name: "副架",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11415,
                            name: "后箱拉杆",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11416,
                            name: "单向阀",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        //进油管：1个	回油管：1个	篷布：1个	电动马达：1个	篷布滑道：1个	滑轮：2个	链轮：2个	链条：1根
                        {
                            id: 11417,
                            name: "进油管",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11418,
                            name: "回油管",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11419,
                            name: "篷布",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11420,
                            name: "电动马达",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11421,
                            name: "篷布滑道",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11422,
                            name: "滑轮",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11423,
                            name: "链轮",
                            maxLoadQty: 2,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11424,
                            name: "链条",
                            maxLoadQty: 1,
                            unit: '根',
                            parts: []
                        },
                        //篷布电机控制箱：1个	电机：1个
                        {
                            id: 11425,
                            name: "篷布电机控制箱",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                        {
                            id: 11426,
                            name: "电机",
                            maxLoadQty: 1,
                            unit: '个',
                            parts: []
                        },
                    ]
                }
            ]
        }
        ]
}

export default upList;

<template>
    <main-submenu :index="index" v-if="menu.children.length > 0" @submenuClick="submenuClick(index)">
        <template slot="title">
            <i class="iconfont" v-if="menu.icon" v-html="menu.icon">{{menu.icon}}</i>
            <span>{{menu.name}}</span>
        </template>
        <menu-item v-for="item in menu.children" :key="item.id" :menu="item"
                   :index="item.id"></menu-item>
    </main-submenu>
    <main-menu-item :index="index" v-else>{{menu.icon ? '' : menu.name}}
        <i class="iconfont" v-if="menu.icon" v-html="menu.icon">{{menu.icon}}</i>
        <span slot="title" v-if="menu.icon">{{menu.name}}</span>
    </main-menu-item>
</template>

<script>
	import mainSubmenu from "@/components/main-menu/src/submenu.vue"
	import mainMenuItem from "@/components/main-menu/src/menu-item.vue"
export default {
	components: {
		mainSubmenu,
		mainMenuItem
	},
    name: "menuItem",
    props: ["menu", "index"],
		methods:{
			submenuClick(id){
                this.$emit('onSubmenuClick', id)
			}
		}
}
</script>

<style scoped>

</style>

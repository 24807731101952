<template>
	<div class="nav-menu">
		<main-menu @select="categoryChange" class="el-menu-vertical-demo" text-color="#67d3ff" @open="handleOpen"
			@close="handleClose" :unique-opened="true">
			<main-menu-item index="0">
				<i class="iconfont">&#xe822;</i>
				<span slot="title">全部</span>
			</main-menu-item>
			<menu-item class="nav-menu-item" v-for="item in menuList" :key="item.id"
                       :menu="item" :index="item.id"></menu-item>

            <menu-item v-for="item in upList.data" :key="item.id" :menu="item"
                       :index="item.id"></menu-item>
		</main-menu>
	</div>
</template>

<script>
	import menuItem from "./menuItem.vue"
	import mainMenu from "@/components/main-menu/src/menu.vue"
	import mainMenuItem from "@/components/main-menu/src/menu-item.vue"
    import upList from "@/api/upList";
	export default {
		components: {
			menuItem,
			mainMenu,
			mainMenuItem
		},
		props: ["menuList"],
		data() {
			return {
                //上装件列表
                upList: upList
            }
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
                this.$emit('categoryChange', key)
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			categoryChange(id) {
				console.log(id)
				this.$emit('categoryChange', id == 0 ? '' : id)
			}
		}
	}
</script>

<style lang="scss">
	.nav-menu{
		.nav-menu-item{
			> ul{
				max-height:400px;
				overflow-y: auto;
			}
		}
	}
</style>
